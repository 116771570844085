.productcard {
  box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
  color: $black_text !important;
  text-decoration: none !important;
  height:400px ;

}

.productimg {
  height: 300px;
  // width: 200px;
  justify-content: center;
  display: inherit;
  align-items: center;
 img{
  object-fit: cover;
  object-fit: cover;
  resize: both;
  height: 290px;
 }
}

@media only screen and (max-width: 767px) {

  .detailsproductimg{
    .slick-track{
    display: flex !important;
    }
    .slick-slide{
      padding: 5px;
    }
  }

  .productimg {
    height: 200px !important;
    width: 150px !important;
  }
  .productcard {
 
    height:300px ;
  
  }
  
}

@media only screen and (max-width: 767px) {

  .productimg {
    height: 200px !important;
    width: 150px !important;
  }
}

.psubtitle {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

a {
  text-decoration: none !important;
}


// ===============

// section {
//   width: 100%;
//   margin: 2rem auto;
//   display: grid;
//   // gap: 0.75rem;
//   grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
// }

// .card {
//   width: 100%;
//   cursor: default;
//   padding: 0.1rem;
//   border-radius: 0.25rem;
//   background-color: #F2F2F2 !important; 
//   transition: transform 0.3s ease-in-out;
//   padding: 32px 32px 32px 32px;

// }

// /* .card:hover {
//   transform: translateY(-0.5rem);
// } */
// .card-round {
//   width: 100%;
//   height: 15rem;
//   // overflow: hidden;
//   overflow: visible;
//   position: relative;
//   border-radius: 50%;
//    background-color: #fdbe6c;

// }
// .card-img {
//   width: 100%;
//   height: 15rem;
//   overflow: hidden;
//   position: relative;
//   border-radius: 50%;

// }

// .card-img img {
//   width: 100%;
//   height: 100%;
//   display: block;
//   // object-fit: cover;
//   object-fit: scale-down;
//   object-position: center;
// }

//  figcaption {
//   background-color:#ababab !important;
//   color: #fff;
//   font-size: 0.85rem;
//   padding: 0.5rem;
//   width: auto;
// /*   position: absolute; */
//   bottom: fixed;

// }
.zoom img {
  padding: 50px;
  background-color: #fff;
  transition: transform .2s; /* Animation */
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

 .teemp{
  height: 200px !important;
  width: 200px !important;
 }
