.popolarim1 {
  width: 600px;
  height: 700px;
  object-fit: cover;
}
.rested{
  margin: 0 !important;
  padding: 0 !important;
  width: 1000px;
  object-fit: cover;
}


.trendycontainer {
  background: $white_bg;
  padding: 30px 10px 30px 10px;
}

.profile-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px #222;
}
.profile-card:hover .profile-img img {
  transform: scale(1.2);
}
@media only screen and (min-width:1400px) and (max-width: 2000px){
  .profile-card .profile-img img {
    height: 34vh !important; 
  }
}
.profile-card .profile-img img {
  width:100%;
  height: 60vh;
  transition: transform 1s;
}

@media screen and (max-width: 968px) {
    .profile-card .profile-img img {
      height: 35vh; 
    }
    .profile-card{
        margin-top: 20px;
    }
    .rested{
      margin: 0 !important;
      padding: 0 !important;
      // width: 400px !important;
    }
    .slick-initialized .slick-slide{
      width: 220px !important;
    }
  }
  
  @media screen and (max-width: 480px) {
    .profile-card .profile-img img {
      height: 30vh; 
    }
    .rested{
      margin: 0 !important;
      padding: 0 !important;
      width: 250px;
    }
    .slick-initialized .slick-slide{
      width: 150px !important;
    }
  }
.profile-card .profile-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40%;
  // border: 3px solid #9e9c9c;
  // border-right: 3px solid transparent;
  // border-bottom: 3px solid transparent;
  transform: scale(1, 1);
  transition: all 0.5s linear;
}
.profile-card .profile-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 40%;
  // border: 3px solid #9e9c9c;
  // border-left: 3px solid transparent;
  // border-top: 3px solid transparent;
  transition: all 0.5s linear;
}
.profile-card:hover .profile-content:before {
  top: 20px;
  left: 20px;
}
.profile-card:hover .profile-content:after {
  bottom: 20px;
  right: 20px;
}

.profile-card .profile-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50% 4%;
  text-align: center;
}
.profile-card .profile-content:hover {
  background-color: rgba(253, 190, 108, 0.5);
}
.profile-card .profile-content .title {
  font-size: 24px;
  color: #333;
  opacity: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: translateY(-100px);
  transition: all 900ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}
.profile-card:hover .title {
  opacity: 1;
  transform: translateY(0);
}
.profile-card:hover .title::after {
  content: "";
  display: block;
  width: 50%;
  margin: 10px auto;
  border-bottom: 2px solid #222;
}
.profile-card .profile-content .title > span {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  text-transform: capitalize;
}
.profile-card .profile-content .social-link {
  margin: 20px 0;
  padding: 0;
  opacity: 0;
  transform: translateY(100px);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}
.profile-card:hover .social-link {
  opacity: 1;
  transform: translateY(0);
}
.profile-card .profile-content .social-link li {
  display: inline-block;
  list-style: none;
  margin: 0 4px;
}
.profile-card .profile-content .social-link li a {
  color: #333;
  width: 30px;
  height: 25px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
}
.slick-dots{
  display: none !important;
}