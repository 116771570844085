.font-family{
  font-family: 'Poppins', sans-serif !important;
}
.font-weight-light{
    font-weight: 300;
  }
  .font-weight-normal{
    font-weight: 400;
  }
  .font-weight-medium{
    font-weight: 500 !important;
  }
  .font-weight-semibold{
    font-weight: 600;
  }
  .font-weight-bold{
    font-weight: 700;
  }
  .font-10{
    font-size: 10px; //12
  }
  .font-12{
    font-size: 12px;
  }
  .font-14{
    font-size: 14px;
  }
  .font-16{
    font-size: 16.7px;
  }
  .font-19{
    font-size: 19px;
  }
  .font-20{
    font-size: 20px;
  }
  .font-15{
    font-size: 15px; // 24
  }
    .font-16{
      font-size: 16px;
    }
    .font-18{
      font-size: 18px; //36
    }
  
  .font-20{
    font-size: 20px;
  } 
  .font-22{
    font-size: 22px;
  } 
  .font-24{
    font-size: 24px;
  } 
  .font-26{
    font-size: 26px;
  }
  .font-28{
    font-size: 28px;
  }

  .font-26{
    font-size: 26px; //40
  }
  .font-30{
    font-size: 30px; //48
  }
  .font-32{
    font-size: 32px; // 64
  }
  .font-38{
    font-size: 32px; // 64
  }
  .font-46{
    font-size: 46px; //93
  }
  .font-50{
    font-size: 50px; // 96
  }
  .font-54{
    font-size: 54px; // 96
  }
  .font-56{
    font-size: 56px; // 96
  }
  .cursor-pointer{
    cursor: pointer;
  }
  @media only screen and (min-width:1400px) and (max-width: 2000px){
    .font-75{
      font-size: 20px; //36
    }
  }
  @media (max-width: 768px) {
    .font-17{
      font-size: 17px; //36
    }
    .font-15{
      font-size: 15px; //36
    }
    .font-13{
      font-size: 13px !important; //36
    }
    .font-11{
      font-size: 11px !important; //36
    }
    .font-21{
      font-size: 21px; //36
    }
    .font-27 {
      font-size: 27px;
  }
    .font-33{
    font-size: 33px;
    }
  }