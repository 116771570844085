.navbr {
  padding: 0 !important;
  border: 0.5px solid rgba(173, 173, 173, 1) !important;
  border: none;
}

.navh {
  background-color: $navbar_bg !important;

}

.navbtn {
  text-decoration: none;
  font-size: 16px;
  color: $black_text !important;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media (min-width: 991px) and (max-width: 1172px) {
  .navbtn {
    font-size: 14px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

}

@media (max-width: 768px) {
  .navh {
    padding-right: 10px !important;
  }
}


 .headercontactcolour a{
  color: black !important;
 }
  