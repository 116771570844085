
    .aboutbg{
        background-color:$about_us_bg;
    }
    .newtrendy{
        color: $black_text !important;
    }
//     .saleleft {
//         background-color: rgba(247, 244, 235, 1);
//       }
//       .saleright {
//         background-color: rgba(172, 148, 119, 1);
//       }
//       .main-sale {
//         position: relative;
//       }
//       .saleround {
//         background: rgba(252, 233, 236, 1);
//         border-radius: 50%;
//         width: 300px;
//         height: 300px;
//         position: absolute;
//         translate: 110px;
       
//         // top: 30%;
//       }
//       .sale-main {
//         height: 80vh;
//         translate: 42px 10px;
//       }
      
//       .saleim {
//         position: absolute;
//         height: 80vh;
//         translate: 10px -108px;
//       }
//       .onsale {
//         font-size: 80px;
//         font-weight: 500;
//       }


    /* Default order for desktop view */
    // .about-postion {
    //     display: flex;
    //     flex-direction: row;
    // }

    // .item {
    //     width: 25%;
    //     padding: 10px;
    //     text-align: center;
    // }

    // /* Media query for mobile view */
    // @media screen and (max-width: 768px) {
    //     .about-postion {
    //         flex-direction: column;
    //     }

    //     /* Change the order of items for mobile view */
    //     .item:nth-child(1) {
    //         order: 2;
    //     }

    //     .item:nth-child(2) {
    //         order: 1;
    //     }

       
    // }
  


   /* Default order for desktop view */
   .container {
    display: flex;
    flex-direction: row;
}

.item {
    width: 25%;
    padding: 10px;
    text-align: center;
}

/* Media query for mobile view */
@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    /* Change the order of items for mobile view */
    .item:nth-child(1) {
        order: 4;
    }

    .item:nth-child(2) {
        order: 3;
    }

    .item:nth-child(3) {
        order: 2;
    }

    .item:nth-child(4) {
        order: 1;
    }
    .aboutmarginbottom{
        margin-bottom: 30px;
    }
}
.mainaboutus{
    background-color: $about_us_bg;
}
.about-text{
    text-align: justify;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
}
// =================
.cards-wrapper {
    display: flex;
    justify-content: center;
  }
  .card img {
    max-width: 100%;
    max-height: 100%;
  }