.body {
  background-image: 'url("images/coming_soon.jpeg")',
}

.productbannerleft {
  background: rgba(247, 244, 235, 1);
}

.productbannerright {
  background: rgba(217, 217, 217, 1);
  position: relative;
}

.logo {
  height: 100px;
}

/* Add this to Navbar.css */
.navbtn.active {
  background: #FDBE6C;
  border: 1px solid #fca636;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px 10px 20px 10px;
  font-weight: bold;
  padding: 6px 18px 8px 18px !important;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}


.bannerimg {
  position: absolute;
  margin-left: -29%;
}

.bannerformmail {
  border-bottom: 1px rgb(255, 255, 255) solid !important;
  background: rgba(121, 120, 120, 0.1);
  backdrop-filter: blur(2px);
  font-weight: 700;
  color: white;
  border: none;
}

.bannerformmail:active {
  border: none !important;
}

.bannerconatctform {
  background: rgba(121, 120, 120, 0.5);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(7px);
  border-style: solid;
  // border-color: rgba(255, 255, 255, 1);
  position: absolute !important;
  // margin-left: 65%;
  // top: 30%;
  width: 300px;
  // .translate-middle-contact {
  //   transform: translate(-36%,-40%) !important;
  // }
}

.contact-top-50 {
  transform: translate(-17%, 1%) !important;
}

.bannerformessage {
  background: rgba(110, 114, 111, 0.1) !important;
  border-bottom: 1px rgb(255, 255, 255) solid !important;
  border: none;
  color: white !important;
}

.btn-send {
  background-color: #676767;
  border: none;
  font-weight: 700;
}

// .container-fluid {
//   display: flex;
//   flex-direction: row;
// }

// .item1 {
//   order: 1; /* Set the order property for this item */
//   flex: 1; /* Ensure both items take up equal width */
// }

// .item2 {
//   order: 2; /* Set the order property for this item */
//   flex: 1; /* Ensure both items take up equal width */
// }

// @media only screen and (max-width: 767px) {
//   .container-fluid {
//     flex-direction: row-reverse; /* Change flex direction for mobile view */
//   }

//   .item1 {
//     order: 2; /* Set the order property for this item */
//   }

//   .item2 {
//     order: 1; /* Set the order property for this item */
//   }
// }
@media only screen and (max-width:380px) {
  .banner {
    height: 300px !important;
  }
}
@media only screen and (min-width:380px) and (max-width: 420px){
  .banner {
    height: 310px !important;
  }
}
@media only screen and (min-width:420px) and (max-width: 640px){
  .banner {
    height: 390px !important;
  }
}
@media only screen and (min-width:640px) and (max-width: 913px){
  .banner {
    height: 500px !important;
  }
}
@media only screen and (min-width:913px) and (max-width: 1400px){
  .banner {
    height: 700px !important;
  }
}

@media only screen and (min-width:1400px) and (max-width: 2000px) {
  .banner {
    height: 1000px !important;
  }
  .bannerconatctform{
    margin-top: 100px;
    width: 400px !important;
  }
  .input-field{
    width: 300px !important;
  }
}



// @media only screen and (min-width:767) and (max-width: 991px) {
//   .bannerimg {
//     position: relative !important;
//     margin-left: 0 !important;
//   }
// }
@media only screen and (max-width: 1172px) {
  // .banner {
  //   position: initial;
  // }

  .bannerimg {
    position: relative !important;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .offcanvas-header {
    background: #F7F4EB;
  }

  .offcanvas-body {
    background: #F7F4EB;
  }

  .navbtn {
    text-decoration: none;
    border-left: none !important;
    padding: 10px;


  }
}




// ============================
.contact-main {
  .form-control {
    display: block;
    width: 100%;
    padding: .175rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white !important;
    appearance: none;
    background-color: transparent !important;
    background-clip: padding-box;
    border-bottom: 1px solid white !important;
    border-radius: 0px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .input-field {
    border: none;
    border-bottom: white 1px solid;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: white;
    font-weight: 600;
  }

  .input-fieldm {
    border: white 1px solid;
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }

  .input-fieldm::placeholder {
    color: white;
  }

  .was-validated .form-control:invalid {
    border-bottom: white 1px solid;
    border: none;
  }

  .was-validated .form-check-input:valid~.form-check-label {
    color: #fff !important;
  }

  .was-validated .form-check-input:valid:checked {
    background-color: rgb(253, 190, 108) !important;
    border-color: rgb(253, 190, 108) !important;
  }

  .was-validated .form-control:invalid {
    background-image: none;
    border: none;
  }

  .form-label {
    margin-bottom: 0px;
  }

  .form-check-input:checked {
    background-color: rgb(253, 190, 108) !important;
    border-color: rgb(253, 190, 108) !important;
  }
}

.contact-card-shadow {
  .was-validated .form-control:invalid {
    border-bottom: white 1px solid;
    border: none;
  }

  .was-validated .form-check-input:valid~.form-check-label {
    color: #fff !important;
  }

  .was-validated .form-check-input:valid:checked {
    background-color: rgb(253, 190, 108) !important;
  }

  .was-validated .form-control:invalid {
    background-image: none;
    border: var(--bs-border-width) solid var(--bs-border-color);
  }

  .btn-send {
    border-radius: 4px;
  }
}

// .fixed-image {
//   width: 200px !important; /* Set the desired width */
//   height: 200px !important; /* Set the desired height */
//   object-fit: cover; /* Apply object-fit: cover */
//   /* You can adjust the width and height to your desired dimensions */
// }
// .was-validated .form-control:valid{
//   border-color: #fff !important;
//   background-image:none !important;
// }
// .was-validated .form-control:invalid{
//   border-color:#fff !important;
//   background-image:none !important;
// }




// =============

@media (min-width: 768px) {

  /* show 3 items */
  .carousel-inner .active,
  .carousel-inner .active+.carousel-item,
  .carousel-inner .active+.carousel-item+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item,
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item {
    transition: none;
  }

  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  .carousel-inner .active.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* left or forward direction */
  .active.carousel-item-left+.carousel-item-next.carousel-item-left,
  .carousel-item-next.carousel-item-left+.carousel-item,
  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item,
  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* farthest right hidden item must be abso position for animations */
  .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* right or prev direction */
  .active.carousel-item-right+.carousel-item-prev.carousel-item-right,
  .carousel-item-prev.carousel-item-right+.carousel-item,
  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item,
  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

.paddleftcontact {
  padding-left: 33px;
}

// ==================================================================================

:root {
  --gradient: linear-gradient(to left top, #ac9477 10%, #FDBE6C 90%) !important;
}



.card {
  // background: #222;

  // margin-bottom: 2rem;
}

.btn {
  border: 5px solid;
  border-image-slice: 1;
  background: var(--gradient) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border-image-source: var(--gradient) !important;
  text-decoration: none;
  transition: all .4s ease;
  border: 1px solid #ac9477 !important;
  // color: rgba(250, 250, 250, 0.8);
  color: #0000 !important;
}

.btn:hover,
.btn:focus {
  background: var(--gradient) !important;
  -webkit-background-clip: none !important;
  -webkit-text-fill-color: #fff !important;
  border: 1px solid #fff !important;
  box-shadow: #ac9477 1px 0 1px;
  text-decoration: none;
}

// .card-img-top {
//   width: 100%;
//   height: 15vw;
//   object-fit: cover;
// }