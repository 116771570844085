.popbtn {
  border: none;
  background: linear-gradient(0deg, #ababab, #ababab);
  color: #fff;
  width: 260px;
  height: 60px;
  position: absolute;
  top: 83%;
}
.tittlepop {
  top: -130%;
  position: absolute;
  font-weight: 700;
}
.scfsda {
  position: relative;
  margin-top: 22%;
  margin-bottom: 5%;
}
.backofround {
  height: 260px;
  width: 260px;
  border: rgb(224, 212, 212) 2px solid;
  background-color: #eeeded;
  position: relative;
}
.popularcat {
  justify-content: space-between;
}
.roundpopular {
  height: 260px;
  width: 260px;
  border-radius: 50%;
  background-color: #fdbe6c;
  position: absolute;
  bottom: 50%;
}
.imgpp {
  position: absolute;
  bottom: 15%;
  height: 430px !important;
  width: 300px;
}
.adjs {
  margin: 0 !important;
  padding: 0 !important;
}
@media only screen and (min-width:1400px) and (max-width: 2000px){
  .popular-mains {
    margin-top: 35% !important;
    margin-bottom: 5%;
  }
}
@media screen and (min-width: 1136px) and (max-width: 1208px) {
  .roundpopular {
    height: 210px;
    width: 210px;
  }
  .backofround {
    height: 210px;
    width: 210px;
  }
  .popbtn {
    width: 210px;
  }
  .imgpp {
    height: 350px !important;
  }
  .tittlepop {
    top: -140%;
    position: absolute;
    font-weight: 700;
  }
  .scfsda{
    position: relative;
    margin-top: 30%;
    margin-bottom: 5%;
  }
}
@media screen and (min-width: 995px) and (max-width: 1136px) {
  .roundpopular {
    height: 210px;
    width: 210px;
  }
  .backofround {
    height: 210px;
    width: 210px;
  }
  .popbtn {
    width: 210px;
  }
  .imgpp {
    height: 350px !important;
  }
  .tittlepop {
    top: -130%;
    position: absolute;
    font-weight: 700;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 40%;
  //   margin-bottom: 5%;
  // }
}
@media screen and (min-width: 853px) and (max-width: 995px) {
  .roundpopular {
    height: 210px;
    width: 210px;
  }
  .backofround {
    height: 210px;
    width: 210px;
    margin-top: 90%;
  }
  .popbtn {
    width: 210px;
  }
  .imgpp {
    height: 350px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 50%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: 0%;
    position: absolute;
    font-weight: 700;
  }
}
@media screen and (min-width: 770px) and (max-width: 853px) {
  .roundpopular {
    height: 210px;
    width: 210px;
  }
  .backofround {
    height: 210px;
    width: 210px;
    margin-top: 90%;
  }
  .popbtn {
    width: 210px;
  }
  .imgpp {
    height: 350px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 60%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: 0%;
    position: absolute;
    font-weight: 700;
  }
}
@media screen and (min-width: 636px) and (max-width: 770px) {
  .roundpopular {
    height: 210px;
    width: 210px;
  }
  .backofround {
    height: 210px;
    width: 210px;
    margin-top: 70%;
  }
  .popbtn {
    width: 210px;
  }
  .imgpp {
    height: 350px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 60%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: 0%;
    position: absolute;
    font-weight: 700;
  }
}
@media screen and (min-width: 590px) and (max-width: 635px) {
  .roundpopular {
    height: 180px;
    width: 180px;
  }
  .backofround {
    height: 180px;
    width: 180px;
    margin-top: 55%;
  }
  .popbtn {
    width: 180px;
  }
  .imgpp {
    height: 250px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 50%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: 0%;
    position: absolute;
    font-weight: 700;
  }
}
@media screen and (min-width: 501px) and (max-width: 590px) {
  .roundpopular {
    height: 180px;
    width: 180px;
  }
  .backofround {
    height: 180px;
    width: 180px;
    margin-top: 70%;
  }
  .popbtn {
    width: 180px;
    height: 40px;
  }
  .imgpp {
    height: 290px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 60%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: 0%;
    position: absolute;
    font-weight: 700;
  }
}

@media screen and (min-width: 408px)  and (max-width: 501px) {
  .roundpopular {
    height: 150px;
    width: 150px;
  }
  .backofround {
    height: 150px;
    width: 150px;
    margin-top: 70%;
  }
  .popbtn {
    width: 150px;
    height: 40px;
  }
  .imgpp {
    height: 230px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 65%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: 0%;
    position: absolute;
    font-weight: 700;
  }
}
@media screen  and (max-width: 408px) {
  .roundpopular {
    height: 150px;
    width: 150px;
  }
  .backofround {
    height: 150px;
    width: 150px;
    margin-top: 70%;
  }
  .popbtn {
    width: 150px;
    height: 40px;
  }
  .imgpp {
    height: 230px !important;
  }
  // .scfsda{
  //   position: relative;
  //   margin-top: 40%;
  //   margin-bottom: 5%;
  // }
  .tittlepop {
    top: -10%;
    position: absolute;
    font-weight: 700;
    margin-left: 2px !important;
  }
}

/* Default styles for all viewports */
.popular-mains {
  position: relative;
  margin-top: 30%;
  margin-bottom: 5%;
}

/* Media query for screens smaller than 768px (e.g., smartphones) */
@media (max-width: 768px) {
  .popular-mains {
    margin-top: -25%;
    margin-bottom: 3%;
  }
}

/* Media query for screens between 768px and 1024px (e.g., tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .popular-mains {
    margin-top: 0%;
    margin-bottom: 4%;
  }
}

/* Media query for screens larger than 1024px (e.g., desktops) */
@media (min-width: 1025px) {
  .popular-mains {
    margin-top: 20%;
    margin-bottom: 5%;
  }
}
