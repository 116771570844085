.lcationinfo{
    background: rgba(118, 111, 111, 1);
   

}
.sndtext{
    color: rgba(253, 190, 108, 1);
   
 }
.alladress a{
    line-height: 50px;
    color: $white_text !important;
    text-decoration: none;
    list-style: none;
    
}
.shopname:hover{
    color: rgba(253, 190, 108, 1);
}
.shopname:focus{
    color: rgba(253, 190, 108, 1);
}
.map{
    position: relative;
}
.adresslaoction{
    position: absolute;
    width: 300px !important;
    bottom: 3%;
    right: 10%;
    box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
}
hr{
    margin: 0 !important;
    padding: 0 !important;
}
.cntlcn{
    color: white;
    background-color: rgba(253, 190, 108, 1);

}

.dec{
  
    border: 1px  solid rgb(167, 167, 167);
    background-color: $white_bg;
}
.logoonlocation{
    position: absolute;
    top: 2%;
    left: 30%;
}

.counter{
    background: rgba(217, 217, 217, 1);
}


@media screen and (min-width:600px) and (max-width:991px) {
    .adresslaoction{
        position: absolute;
        width: 250px;
        box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
    }
}

@media screen and (max-width:600px) {
    .adresslaoction{
        position:relative !important; 
        bottom: 0%;
        right: 0%;
        width: 100% !important;
        box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
    }
}
.active-name-color{
    .active-link {
        color: rgb(253, 190, 108) !important;
      }
}

.card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    margin: -150px;
    float: left;
    perspective: 500px;
}  
  .content {
    position: absolute;
    width: 100%;
    height: 100%;

    transition: transform 1s;
    transform-style: preserve-3d;
  }
  
  .card:hover .content {
    transform: rotateY( 180deg ) ;
    transition: transform 0.5s;
  }
  
  .front,
  .back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
  }
  
  .back {
    transform: rotateY( 180deg );
  }
  .addshopscroll{
    height: 600px;
    overflow-y: scroll;
  }

  
/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
