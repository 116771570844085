.location-main{
  margin: 0px;
  padding: 0px;
    .locationcontainer{
        background: $banner_bg;
        padding: 70px 10px 70px 10px;
        
    }
    .title-location{
        padding-bottom: 20px;
    }

    // .zoom img {
    //   padding: 50px;
    //   background-color: #fff;
    //   transition: transform .2s; /* Animation */
    //   width: 900px !important;
    //   height: 900px !important;
    //   margin: 0 auto;
    // }
    
    // .zoom:hover {
    //   transform: scale(5.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    // }
}
.roundf:hover {
  transform: scale(1.1);
  opacity: 5;
}
.roundf {
  transition: transform 0.5s;
  opacity: 1.0;
}
.slick-arrow{
  display: none !important;
}

.contact-card-shadow .was-validated .form-check-input:valid ~ .form-check-label{
  color: black !important;
}
