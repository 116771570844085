.bestseller-main {
  position: relative;
  background-image: url("../../images/Homebestseller/orangebg.png");
  background-size: cover;
  padding-bottom: 135px;
}

.catrogieimgbrand {
  border-radius: 50%;
  background-color: $white_bg;
  width: 150px;
  height: 150px;
  box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, 0.2);
}

.catrogieimglocation {
  border-radius: 50%;
  background-color: $white_bg;
  box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.2);
  // width: 220px;
  // height: 220px;
  width: 180px;
  height: 180px;
}

.brandcontainer {
  background: $white_bg;
  padding: 30px 10px 30px 10px;

}

.brandcontainerhome {
  background: $light_gray_bg;
  padding: 30px 10px 30px 10px;
}

.Brandsbtn {
  background-color: $brown_bg;
  border: none;
  color: aliceblue;
  border-radius: 4px;
}

.brandsback {
  background-color:  #fff;
}


.brand-img-size {

  width: 64%;
  object-fit: cover;

}

.brandlistellipse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
}

@media only screen and (min-width:452px) and (max-width: 660px) {
  .catrogieimglocation {
    width: 130px !important;
    height: 130px !important;
  }
}

@media only screen and (min-width:453px) and (max-width: 590px) {
  .catrogieimgbrand {
    width: 130px !important;
    height: 130px !important;

  }
}

@media only screen and (max-width: 453px) {
  .catrogieimgbrand {
    width: 100px !important;
    height: 100px !important;
  }

  .catrogieimglocation {
    width: 100px !important;
    height: 100px !important;
  }

  .brandcontainer {
    background: $brand_bg;
    padding: 30px 0px 30px 20px;

  }

  .bestseller-main {
    padding-bottom: 40px;
  }
}

.brandcard:hover{
  background: #fedeb4;
}

.carrds {
  // background-color: rgb(225, 216, 216);
}

.brand-card-padding {
  padding: 30px 10px 0px 10px;
}
@media only screen and (min-width:1400px) and (max-width: 2000px){
  .brandcard{
    height: 400px !important;
  }
  .ltext{
    font-size: 15px !important;
  }
}
.brandcard {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: black !important;
  height: 340px;
}


.designtext {
  font-weight: 600;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.ellipsetext {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
}

.brandNamecolor {
  background-color: #fedeb4;
}

.brandsescrip {
  text-align: justify;
}

// ==================
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ECECEC;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 15px 12px rgba(0, 0, 0, 0.02);
}

#shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}

.img-circle {
  border-radius: 50%;
  vertical-align: middle;
  max-width: 90px;
  min-height: 90px;
  transform-style: preserve-3d;
  margin: 0 auto 17px;
}

#customer-testimonoals {
  margin-top: 6%;
}

.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
  background: linear-gradient(100deg, #FF8066, #FF918D);
  /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
}

#customer-testimonoals .item {
  text-align: center;
  padding: 50px;
  margin-bottom: 0px;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}

#customer-testimonoals .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1.0, 1.0, 1);
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #56423D;
  transform: translate3d(0px, -50%, 0px) scale(0.8);
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
  /* background: #eaa400; */
  background: #56423D;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
}