// background color
$white_bg: #FFFFFF;
$banner_bg: #F7F4EB;
$navbar_bg: #FBFBFB;
$about_us_bg:#FFFEFB;
$light_pink_bg:#FCE9EC;
$brown_bg:#AC9477;
$light_orange_bg:#FDBE6C;
$medium_gray_bg:#ABABAB;
$light_gray_bg:#EFECE7;
$brand_bg:#F2F2F2;
$counter_gray_bg:#D9D9D9;


$light_orange_category_bg:#FFF7DA;
$light_pink_category_bg:#FCEBEE;
$gray_card_bg:#EDEDED;
$brown_card_bg:#E9E0D9;
$light_blue_category_bg:#E9FFFC;
$dark_gray_bg:#766F6F;
$drak_gray_semicircle :#989F9D;
$green_banner_bg:#12A189;
$light_banner_bg:#67E8D2;
$sky_banner_bg:#63B1B2;
$yellow_banner_bg:#FDD250;
$black_text:#000000;
$white_text:#FFFFFF;
$orange_text:#FFCB88;








