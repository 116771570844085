/* Hero Banner */
.heroBanner {
  display: flex;
  height: 91vh;
  justify-content: end;
  align-items: center;
}

.contactCard {
  position: absolute !important;
  top: 10rem;
  left: 6rem;
}
.btnbrand {
  background: transparent;
  color: black;
  font-weight: 700;
  border: none;
}

@media only screen and (min-width: 576px) and (max-width: 778px) {
  .main-sale {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1023px) {
  .newtrendy {
    font-size: 20px !important;
    font-weight: 600;
  }
  .onsale {
    font-size: 40px !important;
    font-weight: 500;
  }
  .catagoriespara {
    font-size: 15px;
    font-weight: 200;
    text-align: center;
  }
  .contactbtn {
    width: 8rem !important;
    height: 3rem !important;
  }
  .saleleft {
    height: 500px;
  }
  .saleright {
    height: 500px;
  }
  .saleround {
    width: 250px !important;
    height: 250px !important;
    top: 30% !important;
  }
  .saleim {
    height: 500px !important;
  }
}
@media only screen and (max-width: 767px) {
  .newtrendy {
    font-size: 20px !important;
    font-weight: 600;
  }
  .onsale {
    font-size: 50px !important;
    font-weight: 500;
  }
  .catagoriespara {
    font-size: 15px;
    font-weight: 200;
    text-align: center;
  }
  .contactbtn {
    width: 8rem !important;
    height: 3rem !important;
  }
  .saleleft {
    height: 400px;
  }
  .saleright {
    height: 400px;
  }
  .saleround {
    width: 250px !important;
    height: 250px !important;
    top: 20% !important;
  }
  .saleim {
    height: 400px !important;
  }
}
/* sale */
.mediaforsaleimg{
  height: 600px;
}
/* Mobile view */
@media screen and (min-width: 581px) and (max-width: 768px) {
  .mediaforsaleimg {
    height: 800px; /* Set the height for mobile devices */
  }
}
@media screen and (min-width: 400px) and (max-width: 581px){
  .mediaforsaleimg {
    height: 900px; /* Set the height for mobile devices */
  }
}
@media screen and (max-width: 400px){
  .mediaforsaleimg {
    height: 950px; /* Set the height for mobile devices */
  }
}
/* Tablet view */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mediaforsaleimg {
    height: 600px; /* Set the height for tablets */
  }
}

/* Large view */
@media screen and (min-width: 1024px) {
  .mediaforsaleimg {
    height: 600px; /* Set the height for larger screens */
  }
}


.readmorefunction{
  border: none;
  background: transparent;
  font-weight: 900;
}
.saleleft {
  background-color: rgba(247, 244, 235, 1);
}
.saleright {
  background-color: rgba(172, 148, 119, 1);
}
.main-sale {
  position: relative;
}
.saleround {
  background: rgba(252, 233, 236, 1);
  border-radius: 50%;
  width: 350px;
  height: 350px;
  position: absolute;
  top: 30%;
}
.sale-main {
  height: 80vh;
}

.saleim {
  position: absolute;
  height: 80vh;
}
.onsale {
  font-size: 80px;
  font-weight: 500;
}
.newtrendy {
  font-size: 40px;
  font-weight: 600;
}
.catagoriespara {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
}
.popular-main {
  margin-top: 200px;
}
.contactbtn {
  /* height: 3.5rem; */
  background-color: #ac9477 !important;
  border: 1px solid #ac9477 !important;
  border-radius: 4px !important;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  min-width: 146px;
  padding: 10px 20px;
}
/* navbar */



/* sale categories css */


.main-sale{
  margin: 0px;
  padding: 0px;
}



/* contactus */
.cardcontact {
  width: 42rem;
  height: 25rem;
  background-color: #6c6c6c;
  border-radius: 5rem;
  margin-left: 8rem;
  position: relative;
  margin-top: 5rem;
}
.card2 {
  width: 30rem;
  height: 17rem;
  background-color: rgb(248, 246, 246);
  position: absolute;
  margin-left: 28rem;
  margin-top: -18rem;
  border-radius: 3rem;
}
.contactbg {
  background: #f7f4eb;
}
.contactbg1 {
  background-color: #fdbe6c;
}
.contacthead {
  color: #f7f4eb;
  font-size: 50px;
  padding-top: 50px;
  margin-left: 10rem;
}
.details {
  color: #f7f4eb;
  font-size: 15px;
  margin-left: 6rem;
}
.news {
  font-size: 6px;
}
.shree {
  height: 5rem;
  width: 12rem;
  margin-left: 9rem;
  margin-top: 1rem;
}
.ul1 {
  padding-left: 2rem;
}

/* Default styles for all viewports */

/* Common styles for all viewports */
/* Common styles for all viewports */
@media only screen and (min-width:1400px) and (max-width: 2000px){
  .sellerouter{
    height: 250px !important;
    width: 250px !important;
  }
   .bestsellimg {
    height: 190px;
    width: 130px;
  }
}
.sellerouter {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #f5c082;
  margin-bottom: 50px;
}

.bestsellimg {
  position: absolute;
  bottom: 50px;
  margin-top: -5rem;
}


/* Media query for screens smaller than 768px (e.g., smartphones) */
@media (max-width: 768px) {
  .sellerouter {
    height: 150px;
    width: 150px;
    margin-top: 8rem;
  }
  .bestsellimg {
    height: 190px;
    width: 130px;
  }
}

/* Media query for screens between 768px and 1024px (e.g., tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .sellerouter {
    height: 150px;
    width: 150px;
    margin-top: 8rem;
  }
  .bestsellimg {
    height: 15rem;
    width: 12rem;
  }
}

/* Media query for screens larger than 1024px (e.g., desktops) */
@media (min-width: 1025px) {
  .bestsellimg {
    height: 20rem;
    width: 14rem;
    margin-top: -10rem;
  }
  .bestsellertext{
    width: 14rem !important;
   
  }
  .sellerouter {
    height: 220px;
    width: 220px;
    margin-top: 8rem;
  }
}


.bestseller-main {
  position: relative;
}
.bestsellertext{
  position: absolute;
  top:90%;
  /* margin-left:1.5%; */
  width: 14rem;
 
}

.bestsellertextsss{
 
}
.bestseller {
  font-size: 60px;
  font-weight: 700;
}


.productname {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-top: 2rem;
}
@media (max-width: 768px) {
.bestsellertext{
  position: absolute;
  top:90%;
  /* margin-left:1.5%; */
  /* width: 14rem !important; */
  justify-content: center;
 width: 9rem ;
 
}
}

/* categories */
.categories-main {
  background: rgba(254, 254, 254, 1);
}

.catrogieimg {
  border-radius: 50%;
  background-color: #fff7da;
  box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
  width: 180px;
  height: 180px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .catrogieimg {
    width: 100px;
    height: 100px;
  }
 
}

/* Medium screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .catrogieimg {
    width: 180px;
    height: 180px;
  }
}
/* Product grid */


.roundtext {
  color: black !important;
  text-decoration: none;
  text-transform: uppercase;
} 
.popopop{
  height: 400px;
}

.navbar-toggler:focus {
box-shadow: none !important;
}